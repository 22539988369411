import { css } from "@emotion/core";
import { graphql } from "gatsby";
import React, { useMemo, useState } from "react";
import {
  IndexPageViewQuery,
  WorkSelectionFragment,
} from "../../graphql-schema-types";
import headerLogo from "../assets/downArrow.svg";
import ArrowLink from "../components/Atoms/ArrowLink";
import { AwardCategoryHeading } from "../components/Atoms/CategoryHeading";
import NewsListItem from "../components/Atoms/NewsListItem";
import { Heading1, Heading3 } from "../components/Atoms/Text";
import {
  EntryPresentation,
  HostSpeakerAndJury,
} from "../components/Organisms/SharedAwardPresentation";
import Seo from "../components/Seo";
import { extractWorkData, extractYearData } from "../formatModels";
import "../fragments";
import { useRssFeedItems } from "../hooks";
import { bpLarge, bpMedium, bpSmall } from "../stylingConstants";
import { Button } from "../components/Atoms/Button";
import { trimYearPrefix } from "../polymorphic-utils";

type Work = ReturnType<typeof extractWorkData>;

type Props = {
  data: IndexPageViewQuery;
};

const AsideHeading = ({ children, ...props }: { children: string }) => (
  <Heading3
    css={css`
      display: none;
      @media ${bpMedium} {
        display: block;
      }
      @media ${bpLarge} {
        line-height: 42px;
      }
    `}
    {...props}
  >
    {children}
  </Heading3>
);

const articleElementId = "articles";

export default function IndexPage({
  data: { contentfulSharedContent: data },
}: Props) {
  if (!data?.activeYear) {
    throw new Error("Can't render Index view without active year on startpage");
  }
  const yearData = extractYearData(data?.activeYear);
  const [newsListExpanded, setNewsListExpanded] = useState(false);
  const [initialNews, restNews] = useMemo(
    () => [
      yearData.articles?.slice(0, 5) ?? [],
      yearData.articles?.slice(5) ?? [],
    ],
    [yearData.articles]
  );
  const { winners, nominations } = useMemo(
    () =>
      yearData.awards?.reduce(
        (result, award) => {
          if (award?.winningWork != null) {
            result.winners.push([
              award?.title ?? "",
              extractWorkData(award.winningWork),
            ]);
          }
          if (
            award?.nominatedWorks != null &&
            award.nominatedWorks.length > 0
          ) {
            result.nominations.push([
              award?.title ?? "",
              (award.nominatedWorks.filter(
                (work) => work != null
              ) as WorkSelectionFragment[]).map(extractWorkData),
            ]);
          }
          return result;
        },
        {
          winners: [] as [string, Work][],
          nominations: [] as [string, Work[]][],
        }
      ) ?? { winners: null, nominations: null },
    [yearData.awards]
  );
  return (
    <div
      css={css`
        @media ${bpMedium} {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
        }
      `}
    >
      <Seo image={headerLogo} />
      <section
        css={css`
          margin: 0;
          box-sizing: border-box;
          @media ${bpMedium} {
            width: ${100 / 1.5}%;
            padding-right: 5%;
            border-right: 1px solid #dedede;
          }
        `}
      >
        <div
          css={css`
            text-align: center;
            margin-bottom: 28px;
            @media ${bpSmall} {
              display: none;
            }
          `}
        >
          <ArrowLink
            direction="down"
            href={`#${articleElementId}`}
            onClick={(ev) => {
              ev.preventDefault();
              document
                .getElementById(articleElementId)
                ?.scrollIntoView({ behavior: "smooth", block: "start" });
            }}
          >
            Senaste händelser
          </ArrowLink>
        </div>
        {winners.length === 0 ? null : (
          <>
            <Heading1>Vinnare {yearData.year}</Heading1>
            {winners.map(([cat, winner]) => (
              <React.Fragment key={cat}>
                <AwardCategoryHeading>{cat}</AwardCategoryHeading>
                <EntryPresentation type="winner" data={winner} />
              </React.Fragment>
            ))}
          </>
        )}
        {nominations.length === 0 ? null : (
          <>
            <Heading1
              css={css`
                margin-top: 52px;
                @media ${bpLarge} {
                  margin-top: 64px;
                }
              `}
            >
              Nominerade {yearData.year}
            </Heading1>
            {nominations.map(([cat, nominations]) => (
              <React.Fragment key={cat}>
                <AwardCategoryHeading>{cat}</AwardCategoryHeading>
                {nominations.map((nominee) => (
                  <EntryPresentation
                    key={nominee.id}
                    type="nominated"
                    data={nominee}
                  />
                ))}
              </React.Fragment>
            ))}
          </>
        )}
        <div
          css={
            winners.length === 0 &&
            nominations.length === 0 &&
            css`
              margin-top: -64px;
              @media ${bpLarge} {
                margin-top: -112px;
              }
            `
          }
        >
          <HostSpeakerAndJury year={yearData} />
        </div>
      </section>
      <aside
        id={articleElementId}
        css={css`
          margin: 0;
          box-sizing: border-box;
          @media ${bpMedium} {
            width: ${100 / 3}%;
            padding-left: 5%;
          }
        `}
      >
        {initialNews.length === 0 ? null : (
          <AsideHeading css={{ display: "block" }}>Händelser</AsideHeading>
        )}
        {(newsListExpanded ? [...initialNews, ...restNews] : initialNews).map(
          (article) =>
            article && (
              <NewsListItem
                href={`${yearData.year}/${article.slug}`}
                imageSrc={article.image?.fluid}
                title={trimYearPrefix(article.title)}
                publishDate={article.publishDate}
                preamble={article.preamble?.preamble}
              />
            )
        )}
        {!newsListExpanded && restNews.length > 0 && (
          <Button
            onClick={() => setNewsListExpanded(true)}
            css={{ marginBottom: 36, width: "100%" }}
          >
            Visa fler
          </Button>
        )}
        {data.rssNewsFeedUrl && (
          <RssNewsList
            feedUrl={data.rssNewsFeedUrl}
            maxNumberOfItems={
              data.maxNumberOfRssNewsItemsToDisplay ?? undefined
            }
          />
        )}
      </aside>
    </div>
  );
}

const RssNewsList = ({
  feedUrl,
  maxNumberOfItems,
}: {
  feedUrl: string;
  maxNumberOfItems?: number;
}) => {
  const items = useRssFeedItems(
    "https://www.retriever-info.com/feed/2003957/Extern_feed/index.xml"
  );
  if (items == null || items.length == 0) {
    return null;
  }
  return (
    <>
      <AsideHeading css={{ marginTop: 36, display: "block" }}>
        I andra medier
      </AsideHeading>
      {(maxNumberOfItems ? items.slice(0, maxNumberOfItems) : items).map(
        (item) => (
          <NewsListItem
            key={item.href}
            target="_blank"
            href={item.href ?? ""}
            title={item.title ?? ""}
            publishDate={item.published?.toLocaleDateString("sv-SE") ?? ""}
          />
        )
      )}
    </>
  );
};

export const query = graphql`
  query IndexPageView {
    allContentfulPerson {
      edges {
        node {
          name
        }
      }
    }
    contentfulSharedContent {
      activeYear {
        ...YearSelection
      }
      rssNewsFeedUrl
      maxNumberOfRssNewsItemsToDisplay
    }
  }
`;
